import React from "react"
import * as Styles from "../styles/Item.module.css"

export const Privacy = ({ company }) => {
  return (
    <div className={Styles.box}>
      <h2 className={Styles.title}>プライバシーポリシー</h2>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>はじめに</h3>
        <p className={Styles.text}>
          {company.name}
          (以下、｢当社｣といいます)は、個人情報の保護を社会的責務であると考え、
          お客様に安心してご利用いただけるウェブサイトの管理・運営を行うため、「個人情報保護方針」に基づき、
          以下のとおり「ウェブサイトにおける個人情報の取扱いについて」を定めました。
        </p>
      </div>

      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の取扱いの適用範囲</h3>
        <p className={Styles.text}>
          個人情報の取扱いについては、お客様が当社のサイトを通じて商品の購入、当社へのご連絡、メールマガジンの購読などをご利用された時に適応されます。
          お客様が当社のサイトを利用される際に収集された個人情報は、当個人情報の取扱いについての考え方に従い管理されます。
        </p>
      </div>

      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の利用目的</h3>
        <p className={Styles.text}>
          当社は、お客様から収集させていただいた個人情報、ご注文情報（お客様の注文履歴に関する情報を含む）を、本サービスを提供する目的の他に、
          以下の各号に定める目的のために利用することがあります。
          本サービスの提供または以下に定める目的以外に、当社はお客様の個人情報利用することはありません。
        </p>
        <ol className={Styles.list}>
          <li className={Styles.text}>
            お客様に対して、当社の商品やサービスをご紹介する場合
          </li>
          <li className={Styles.text}>
            当社において、お客様に代行してご注文手続き、ご注文内容の確認、変更手続きを行う場合
          </li>
          <li className={Styles.text}>
            お客様からのお問い合わせに対して回答を行う場合
          </li>
          <li className={Styles.text}>
            お客様に対して、当社のサービスに対するご意見やご感想のご提供をお願いするため
          </li>
          <li className={Styles.text}>
            当社がお客様に別途連絡の上、個別にご了解をいただいた目的に利用するため
          </li>
          <li className={Styles.text}>
            お客様の属性（年齢、住所など）ごとに分類された統計的資料を作成するため
          </li>
          <li className={Styles.text}>
            お客様それぞれの嗜好に適合した情報発信やサービスを提供、表示するため
          </li>
        </ol>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の安全管理について</h3>
        <p className={Styles.text}>
          当社は個人情報の正確性及び安全性を確保する為、個人情報へのアクセス管理、持ち出し手段の制限、不正アクセスおよび、漏洩、紛失、破壊、
          改ざんなどに対しては、合理的な安全対策を講じるとともに、万一、漏洩等個人情報に関する事故が発生した場合には、
          再発防止策を含む適切な対策を速やかに講じます。
        </p>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の預託について</h3>
        <p className={Styles.text}>
          当社は、明示した利用目的の達成の為に必要な範囲で業務を預託する場合があります。その場合は、業務委託先の適切な管理及び監督を行います。
          （業務委託先とは、運送業者、ダイレクトメールの発送のための印刷会社、商品代金未払いの場合の回収委託会社等。）
        </p>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の第三者への開示</h3>
        <p className={Styles.text}>
          当社は、個人情報を本人の許可無く他の事業者や個人などの第三者に提供および公開することはありません。ただし、以下に該当する場合はその限りではありません。
        </p>
        <ol className={Styles.list}>
          <li className={Styles.text}>情報提供について本人の同意がある場合</li>
          <li className={Styles.text}>
            官公庁等の公的機関から法令に基づき開示を求められた場合
          </li>
          <li className={Styles.text}>
            本サイトの運営に関する業務提携先に対して個人情報を開示する場合。ただし、この場合に開示する情報は必要な範囲のみに限定し、開示先に対して契約等により個人情報の管理を義務付けます。
          </li>
        </ol>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>クッキーに関して</h3>
        <p className={Styles.text}>
          クッキーとは、お客様についての情報を含むお客様のハードドライブに蓄えられる1つのデータです。
          クッキーの使用は、弊社のサイト上では決して個人特定情報にリンクされません。
          お客様がクッキーを拒否する場合でも当サイトを使用することができます。
          当サイトは、商品詳細ページ、ショッピングカートでクッキーを使用しています。
          クッキーを拒否した場合はショッピングカートが作動しません。
          当店は不要な情報収集目的でクッキーを利用することはありません。
          （当店に限らず）カート形式でお求めになる場合は、ブラウザのクッキー設定を有効にされておかれることをお薦めします。
          カート・お客様自身の購入履歴の表示以外の目的でクッキーを利用することはありませんので、カート形式でお求めになる場合は、ブラウザのクッキー設定を有効にしてください。
          弊社サイトでは、サイトのユーザービリティーの向上に役立てるためのアクセスログの収集と、お客様がサイトを再度訪れたときに個人情報入力の手間が省け、
          一層便利に利用していただくために、このクッキーを利用しているページがあります。
        </p>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>
          その他インターネット上のプライバシーに関する確認事項
        </h3>
        <p className={Styles.text}>
          当社は、個人情報を本人の許可無く他の事業者や個人などの第三者に提供および公開することはありません。ただし、以下に該当する場合はその限りではありません。
        </p>
        <p className={Styles.text}>
          掲示板など、誰もがアクセスできるインターネット上で自発的に個人情報を開示した場合、その情報は他の利用者によって収集され使用される可能性があることに留意してください。
          メールアドレスを公開すれば、そこに掲示された情報をもとに、望ましくないメッセージを受け取ることがあります。
          お客様には自己の責任において情報を発信することを認識していただく必要があります。
          当ウェブサイトを通じてアクセスできる第三者のサイト及びサービス、又は懸賞や販売促進活動などを通して個人情報を収集している企業等は、
          当社とは独立した個々のプライバシーに関しての規約やデータの収集規約を定めています。当社は、これらの独立した第三者の規約や活動に対していかなる義務や責任も負いません。
        </p>
      </div>
      <div className={Styles.contents}>
        <h3 className={Styles.sub_title}>個人情報の取扱いの変更及び通知</h3>
        <p className={Styles.text}>
          当社は、本個人情報の取扱いについての内容を、当社の営業上の必要性、新法令の施行、既存法令の改正その他の事情により、
          事前の予告なく変更することがあります。
          変更した場合は、本ウェブサイトに掲示し、お知らせします。
          ご利用の際には本ページの最新の内容をご確認ください。
          個人情報、保有個人データ等の取扱いに関するご要望・ご質問等は、下記連絡先までお問い合せください。
        </p>

        <div className={Styles.tbl}>
          <div className={Styles.cell_left}>法人名</div>
          <div className={Styles.cell_right}>{company.name}</div>

          <div className={Styles.cell_left}>代表</div>
          <div className={Styles.cell_right}>{company.president}</div>

          <div className={Styles.cell_left}>住所</div>
          <div className={Styles.cell_right}>
            <p className={Styles.text}>{company.zip_code}</p>
            <p className={Styles.text}>{company.address}</p>
          </div>

          <div className={Styles.cell_left}>Eメール</div>
          <div className={Styles.cell_right}>{company.mail}</div>

          <div className={Styles.cell_left}>営業時間</div>
          <div className={Styles.cell_right_last}>
            {company.open_time}（{company.open_date}）
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy

// extracted by mini-css-extract-plugin
export var box = "Item-module--box--3-XEd";
export var cell_left = "Item-module--cell_left--jTfrx";
export var cell_right = "Item-module--cell_right--6RfBp";
export var cell_right_last = "Item-module--cell_right_last--C0UcT";
export var contents = "Item-module--contents--BWOdX";
export var contents_title = "Item-module--contents_title--VkIMi";
export var list = "Item-module--list--tiUqQ";
export var sub_title = "Item-module--sub_title--nWSHG";
export var tbl = "Item-module--tbl--Pm4kV";
export var text = "Item-module--text--QIsX5";
export var title = "Item-module--title--KOesN";
import React from "react"
import * as Styles from "../styles/Item.module.css"

export const Delivery = ({ company }) => {
  return (
    <div className={Styles.box}>
      <h2 className={Styles.title}>送料とお支払い方法</h2>
      <p className={Styles.contents_title}>お支払い方法</p>

      <div className={Styles.tbl}>
        <div className={Styles.cell_left}>クレジット</div>
        <div className={Styles.cell_right}>
          <p className={Styles.text}>3～10営業日以内に発送</p>
          <p className={Styles.text}>
            購入代金をクレジットカード会社より後日請求させていただきます。
          </p>
          <p className={Styles.text}>
            お支払日は、お客様がご指定の各クレジットカード会社の規約に基づいた指定日のお支払いとなります。
          </p>
        </div>
        <div className={Styles.cell_left}>代金引換</div>
        <div className={Styles.cell_right}>
          <p className={Styles.text}>
            全対象：代引き手数料
            {company.charge > 0 ? (
              <span>{company.commission}円（税込）</span>
            ) : (
              <span>無料</span>
            )}
          </p>
          <p className={Styles.text}>3～10営業日以内に発送</p>
          <p className={Styles.text}>
            購入代金(商品代金+送料)をそのまま現金でお支払いください。
          </p>
        </div>
        {company.amazon_flg > 0 ? (
          <>
            <div className={Styles.cell_left}>アマゾン・ペイ</div>
            <div className={Styles.cell_right}>
              <p className={Styles.text}>3～10営業日以内に発送</p>
              <p className={Styles.text}>
                購入代金をクレジットカード会社より後日請求させていただきます。
              </p>
              <p className={Styles.text}>
                お支払日は、お客様がご指定の各クレジットカード会社の規約に基づいた指定日のお支払いとなります。
              </p>
            </div>
          </>
        ) : (
          ""
        )}

        <div className={Styles.cell_left}>NP後払い</div>
        <div className={Styles.cell_right}>
          <p className="mb-1">
            <a
              href="http://www.np-atobarai.jp/about/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://shopping.body-custom.net/img/wiz_newbanner468x64.png"
                alt="NP後払い"
                className="max-w-full"
              />
            </a>
          </p>
          <p className={Styles.text}>
            ○このお支払方法の詳細：商品の到着を確認してから、「コンビニ」「郵便局」「銀行」で後払いできる安心・簡単な決済方法です。
            請求書は、商品とは別に郵送されますので、発行から14日以内にお支払いをお願いします。
          </p>
          <p className={Styles.text}>
            ○ご注意：後払い手数料：{company.np}
            円後払いのご注文には、株式会社ネットプロテクションズの提供するNP後払いサービスが適用され、
            サービスの範囲内で個人情報を提供し、代金債権を譲渡します。
            ご利用限度額は累計残高で54,000円（税込）迄です。詳細はバナーをクリックしてご確認下さい。
          </p>
          <p className={Styles.text}>
            ◇ご請求書は、商品とは別に郵送されますので、発行から14日以内にお支払いください。
          </p>
          <p className={Styles.text}>
            ※商品より先に到着する場合がございます。あらかじめ、ご了承ください。
          </p>
          <p className={Styles.text}>
            ※お近くのコンビニ・銀行・郵便局にてお支払いいただけます。
          </p>
        </div>
      </div>

      <p className={Styles.contents_title}>配送方法・送料</p>
      <div className={Styles.tbl}>
        <div className={Styles.cell_left}>配送業者</div>
        <div className={Styles.cell_right}>佐川急便にて発送致します。</div>
        <div className={Styles.cell_left}>送料</div>
        <div className={Styles.cell_right}>
          {company.charge > 0 ? (
            <span>
              1回のご注文につき送料{company.shipping}円（税込）/沖縄・離島は
              {company.remote}円（税込）
            </span>
          ) : (
            <span>無料</span>
          )}
        </div>
        <div className={Styles.cell_left}>商品配送</div>
        <div className={Styles.cell_right}>
          <p className={Styles.text}>ご注文後、1週間以内に発送致します。</p>
          <p className={Styles.text}>
            ご自宅への配送をご希望されないお客様は最寄の佐川急便営業所止めもご利用頂けます。
          </p>
          <p className={Styles.text}>
            最寄りの営業所が不明な場合は、住所の欄にご自宅の住所を入力し備考欄に最寄りの営業所止めとご入力下さい。
          </p>
        </div>
      </div>
    </div>
  )
}

export default Delivery

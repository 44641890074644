import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Cart from "./Cart"
import Form from "./Form"

const Main = props => {
  const { arg, val, session_id } = props

  return (
    <div className="grid grid-cols-1 gap-0 mx-auto my-0 max-w-screen-lg">
      <div className="img_box">
        <StaticImage
          width={1080}
          height={241}
          src={"../assets/images/image_1.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={241}
          src={"../assets/images/image_2.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={241}
          src={"../assets/images/image_3.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>

      <Cart arg={arg} val={val} session_id={session_id} />

      <div className="img_box">
        <StaticImage
          width={1080}
          height={210}
          src={"../assets/images/image_4.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={209}
          src={"../assets/images/image_5.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={210}
          src={"../assets/images/image_6.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={170}
          src={"../assets/images/image_7.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={169}
          src={"../assets/images/image_8.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={170}
          src={"../assets/images/image_9.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={179}
          src={"../assets/images/image_10.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={178}
          src={"../assets/images/image_11.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={179}
          src={"../assets/images/image_12.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={178}
          src={"../assets/images/image_13.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={174}
          src={"../assets/images/image_14.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={171}
          src={"../assets/images/image_15.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_16.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_17.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={240}
          src={"../assets/images/image_18.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_19.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_20.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_21.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={239}
          src={"../assets/images/image_22.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1080}
          height={238}
          src={"../assets/images/image_23.png"}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>

      <Cart arg={arg} val={val} session_id={session_id} />

      <style jsx global>
        {`
          .img_box > div {
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            margin: 0 auto -1px;
            padding: 0;
          }
          .img_box > div > img {
            width: 100%;
            max-width: 100%;
          }
        `}
      </style>
    </div>
  )
}
export default Main

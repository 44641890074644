import React from "react"

export const Law = ({ company }) => {
  return (
    <div className="grid grid-cols-1 gap-0 mx-auto mb-5 p-2 max-w-screen-md">
      <div className="mb-5">
        <h2 className="text-3xl text-black-300 leading-9 font-extrabold tracking-tight border-b-2 mb-3 pb-3">
          特定商取引法に基づく表記
        </h2>
        <div className="grid grid-cols-4">
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            販売業者
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.name}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            運営統括責任者名
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.president}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            郵便番号
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.zip_code}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            住所
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.address}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            商品代金以外の料金の説明
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            販売価格とは別に配送料、代引き手数料がかかる場合もございます。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            申込有効期限
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            ご注文後7日以内といたします。ご注文後７日間ご入金がない場合は、購入の意思がないものとし、注文を自動的にキャンセルとさせていただきます。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            不良品
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            輸送中に破損した場合、または不良品・ご注文と異なる商品が届いた場合は、商品到着後8日以内に、弊社までご連絡ください。責任を持ってお取替えさせていただきます。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            返品について
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            <p>お客様都合による返品・交換は受け付けておりません。</p>
            <p>不良品の場合のみ返品、交換はお受けします。</p>
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            販売数量
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            各商品ページにてご確認ください。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            引渡し時期
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            ご注文を受けてから7日以内に発送いたします。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            お支払い方法
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.payment}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            お支払い期限
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            当方からの確認メール送信後7日以内となります。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            返品送料
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            お客様都合による返品につきましてはお客様のご負担とさせていただきます。不良品に該当する場合は当方で負担いたします。
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            電話番号
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.phone}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            公開メールアドレス
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.mail}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            ホームページアドレス
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r px-4 py-2">
            {company.url}
          </div>
          <div className="col-span-4 sm:col-span-1 text-base font-bold bg-gray-200 align-top px-4 py-2">
            サポート対応時間
          </div>
          <div className="col-span-4 sm:col-span-3 border-t border-l border-r border-b px-4 py-2">
            受付時間{company.open_time}（{company.open_date}）
          </div>
        </div>
      </div>
    </div>
  )
}

export default Law

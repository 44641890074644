/**
 * create unique id
 */
const getUniqId = () => {

  const strong = 1000;
  let session_id =
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16);
  return session_id;
};
export default getUniqId;

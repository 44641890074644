import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import getUniqId from "../libs/getUniqId"
import getUserAgent from "../libs/getUserAgent"
import getParameter from "../libs/getParameter"
import addAccessCount from "../libs/addAccessCount"
import Main from "../components/Main"
import Footer from "../components/Footer"

const apiUrl = `${process.env.API_COMPANY_URL}?ref=${process.env.PREFIX}`

const Top = ({ data }) => {
  const [company, setCompany] = useState({})
  const [sessionId, setSessionId] = useState("")
  const [arg, setArg] = useState("")
  const [val, setVal] = useState("")
  const [device, setDevice] = useState(0)

  useEffect(() => {
    const dev = getUserAgent()
    setDevice(dev)

    let sess = sessionStorage.getItem("session_id")
    if (!sess) {
      sess = getUniqId()
      sessionStorage.setItem("session_id", sess)
    }
    setSessionId(sess)

    getParameter()
    let arg = sessionStorage.getItem("arg")
    let val = sessionStorage.getItem("val")

    if (arg !== "") {
      setArg(arg)
    }
    if (val !== "") {
      setVal(val)
    }
    fetch(apiUrl)
      .then(x => x.json())
      .then(x => setCompany(x))
    addAccessCount(dev)
  }, [])

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja-jp" }}
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: `description`,
            content: data.site.siteMetadata.description,
          },
        ]}
      />

      <div className="App">
        <Main arg={arg} val={val} session_id={sessionId} />
      </div>
      <Footer company={company} siteData={data.site.siteMetadata} />
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        copyright
      }
    }
  }
`

export default Top

export const Config = {
  productId: 3,
  companyId: 1,
  deliverTraderId: 2,
  productInfo: [
    {
      id: 9,
      image:"images/ct_01.png",
      variation: 1,
    },
    {
      id: 10,
      image:"images/ct_02.png",
      variation: 2,
    },
    {
      id: 11,
      image:"images/ct_03.png",
      variation: 3,
    },
    {
      id: 12,
      image:"images/ct_04.png",
      variation: 5,
    },
    {
      id: 78,
      image:"images/gift_03.png",
      variation: 7,
    },
  ],
}

export const ExceptParams = [
  "access_token",
  "width",
  "height",
  "guid",
  "aclid",
  "gclid",
]

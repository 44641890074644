import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Config } from "../../site.config"
import { css } from "@emotion/css"
import CartCv01Image from "../assets/images/ct_01.png"
import CartCv02Image from "../assets/images/ct_02.png"
import CartCv03Image from "../assets/images/ct_03.png"
import CartCv05Image from "../assets/images/ct_04.png"
import CartCvGiftImage from "../assets/images/gift_03.png"

const Cart = props => {
  const { arg, val, session_id, flg } = props
  // css emotion
  const cvStyle = css`
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 0 auto -1px;
  `
  const cvForm = css`
    list-style: none;
    box-sizing: border-box;
  `

  return (
    <>
      <div className="img_box">
        <StaticImage
          src={"../assets/images/ct_c1.png"}
          width={1080}
          height={200}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          src={"../assets/images/ct_c2.png"}
          width={1080}
          height={200}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>

      <div className={cvStyle}>
        <form
          className={cvForm}
          name="product_form"
          method="post"
          action={process.env.CART_URL}
        >
          <input
            src={CartCv01Image}
            type="image"
            className="w-full max-w-screen-lg m-0 p-0"
            alt="ご注文はコチラ"
          />
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={Config.productId} />
          <input type="hidden" name="i" value={Config.productInfo[0].id} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input type="hidden" name="argument_id" value={arg} />
          <input type="hidden" name="argument_data" value={val} />
          <input type="hidden" name="SESSION" value={session_id} />
        </form>
        <form
          className={cvForm}
          name="product_form"
          method="post"
          action={process.env.CART_URL}
        >
          <input
            src={CartCv02Image}
            type="image"
            className="w-full max-w-screen-lg m-0 p-0"
            alt="ご注文はコチラ"
          />
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={Config.productId} />
          <input type="hidden" name="i" value={Config.productInfo[1].id} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input type="hidden" name="argument_id" value={arg} />
          <input type="hidden" name="argument_data" value={val} />
          <input type="hidden" name="SESSION" value={session_id} />
        </form>
        <form
          className={cvForm}
          name="product_form"
          method="post"
          action={process.env.CART_URL}
        >
          <input
            src={CartCv03Image}
            type="image"
            className="w-full max-w-screen-lg m-0 p-0"
            alt="ご注文はコチラ"
          />
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={Config.productId} />
          <input type="hidden" name="i" value={Config.productInfo[2].id} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input type="hidden" name="argument_id" value={arg} />
          <input type="hidden" name="argument_data" value={val} />
          <input type="hidden" name="SESSION" value={session_id} />
        </form>
        <form
          className={cvForm}
          name="product_form"
          method="post"
          action={process.env.CART_URL}
        >
          <input
            src={CartCv05Image}
            type="image"
            className="w-full max-w-screen-lg m-0 p-0"
            alt="ご注文はコチラ"
          />
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={Config.productId} />
          <input type="hidden" name="i" value={Config.productInfo[3].id} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input type="hidden" name="argument_id" value={arg} />
          <input type="hidden" name="argument_data" value={val} />
          <input type="hidden" name="SESSION" value={session_id} />
        </form>
      </div>

      <div className="img_box">
        <StaticImage
          src={"../assets/images/gift_01.png"}
          width={1080}
          height={241}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <div className="img_box">
        <StaticImage
          src={"../assets/images/gift_02.png"}
          width={1080}
          height={241}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
      <form
        className="m-0 p-0 align-bottom leading-none"
        style={{ margin: "0 auto -2px" }}
        name="product_form"
        method="post"
        action={process.env.CART_URL}
      >
        <input
          src={CartCvGiftImage}
          type="image"
          className="w-full max-w-screen-lg m-0 p-0"
          alt="ご注文はコチラ"
        />
        <input type="hidden" name="q" value="1" />
        <input type="hidden" name="m" value="1" />
        <input type="hidden" name="h" value={Config.productId} />
        <input type="hidden" name="i" value={Config.productInfo[4].id} />
        <input type="hidden" name="company_id" value={Config.companyId} />
        <input
          type="hidden"
          name="deliver_trader_id"
          value={Config.deliverTraderId}
        />
        <input type="hidden" name="argument_id" value={arg} />
        <input type="hidden" name="argument_data" value={val} />
        <input type="hidden" name="SESSION" value={session_id} />
      </form>
      <div className="img_box">
        <StaticImage
          src={"../assets/images/gift_04.png"}
          width={1080}
          height={169}
          placeholder="blurred"
          quality="65"
          alt="Image"
        />
      </div>
    </>
  )
}

export default Cart
